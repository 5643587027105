
.main-admin {
    height: 100vh;
}
.sidebar {
    background-color: $bg-primary;
    // height: 100vh;
    padding: 0 15px 0 0 !important;
    // max-width: 230px;
    // width: 100%;
    .sidebar-logo {
        text-align: center;
        margin: 30px 7px 45px 0;
    }
}
.sidebar-container {
    .nav {
        list-style: none;
        padding-left: 0;
        .nav-item {
            margin: 3px 0;
            width: 100%;
            a.nav-link {
                font-size: $font-size13;
                color: $text-admin-color;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                padding: 0;
                font-weight: $font-semibold;
                border-radius: 0 10px 10px 0;
                cursor: pointer;
                &:hover, &.active {
                    color: $white-color;
                    background-color: rgb(216 216 216 / 10%);
                    span {
                        &::before {
                            color: #fff;
                        }
                    }
                }
            }
            span {
                padding: 0 16px;
            }
        }
    }
}
.right-sidebar {
    padding: 30px !important;
    background-color: #f8f8f8;
}
