
.flex-table-column-10 {
    width: 10%;
  }
  .flex-table-column-15 {
    width: 15%;
  }
  .flex-table-column-20 {
    width: 20%;
  }
  .flex-table-column-25 {
    width: 25%;
  }
  .flex-table-column-30 {
    width: 30%;
  }
  .flex-table-column-35 {
    width: 35%;
  }
  .flex-table-column-40 {
    width: 40%;
  }
  .flex-table-column-45 {
    width: 45%;
  }
  .flex-table-column-50 {
    width: 50%;
  }
  .flex-table-column-55 {
    width: 55%;
  }
  .flex-table-column-60 {
    width: 60%;
  }
  .txt-center {
    text-align: center;
  }
  .show-all-container {
    margin: 40px 0;
    .show-all-padding {
      width: auto;
      padding: 10px 52px 11px 53px;
      border: 1px solid #0dcaf0 !important;
      color: #0dcaf0 !important;
      text-decoration: none;
      border-radius: 5px;
      background-color: transparent;
      cursor: default;
    }
  }
  .ml-20 {
    margin-left: 20px;
  }
  .table-wrapper {
    .thead {
      .tr {
        height: 50px;
        padding: 0 20px;
        border-radius: 10px;
        box-shadow: 0 20px 40px 10px rgb(224 224 224 / 20%);
        background-color: #ececec;
        display: flex;
        align-items: center;
      }
    }
    .tbody {
      .tr {
        // height: 70px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 20px 40px 10px rgb(224 224 224 / 20%);
      background-color: $white-color;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        &:hover {
            background-color: #f0eff0;
        }
        .td {
          .table-txt {
            color: $heading-color;
            font-size: $font-size15;
            font-weight: normal;
            line-height: normal;
            margin-bottom: 0;
            background-color: transparent;
            border: 0;
            display: flex;
            align-items: center;
            padding-right: 10px;
            overflow-wrap: anywhere;
            text-decoration: none;
            &::after {
                display: none;
            }
            .action-icon {
              margin-left: 6px;
            }
            &.user-object {
              display: flex;
              img {
                  width: 35px;
                  height: 35px;
              }
              .user-info {
                  margin-left: 15px;
                  max-width: 72%;
                  width: 100%;
                  .user-name {
                      font-size: 13px;
                      margin-bottom: 2px;
                      display: flex;
                  }
                  .user-date {
                      font-size: 12px;
                      color: #666666;
                      margin-bottom: 0;
                      display: flex;
                  }
              }
          }
          }
          .store-pic {
              padding-right: 15px;
              width: 40px;
              height: 35px;
          }
        }
      }
    }
    .table-heading {
        font-size: $font-size12;
        color: #666;
        text-transform: uppercase;
    }
  }
  // shorting-colum
  .shorting-colum {
    font-size: 8px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #000;
    text-align: center;
    margin: -2px auto 0 5px;
    &:hover, &:focus {
      color: #000;
    }
    .icon-shorting {
      display: flex;
      .up {
        cursor: pointer;
      }
      .down {
        position: relative;
        left: 1px;
        cursor: pointer;
      }
      a{
        border-top: .5em solid #000;
        border-right: .5em solid transparent;
        border-bottom: 0;
        border-left: .5em solid transparent;
        margin: 1px 0 1px;
        &.up{
          transform: rotate(180deg);
        }
      }
    }
  }
  // list-container
  .list-container{
    padding-left: 15px;
    padding-right: 15px;
    .tr{
      margin-bottom: 10px;
    }
    .table-txt{
      font-size: $font-size13;
      overflow-wrap: anywhere;
      text-decoration: none;
    }
    .table-heading{
      font-weight: bold;
    }
}
.bg-status {
    font-size: $font-size13 !important;
    padding: 3px 20px 5px 20px;
    border-radius: 15px;
    &.active {
        background-color: rgb(0 139 26 / 9%);
        color: #008b1a !important;
    }
    &.Inactive {
        background-color: rgb(255 0 0 / 8%) !important;
        color: $bg-secondary;
    }
    &.without{
      background-color: #ffffffa5 !important;
      color: black !important;
    }
}
.desktop-view-inline {
  padding: 0 10px 0 0;
}
.remove-underline {
  text-decoration: none !important;
}