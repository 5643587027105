@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap');
// @import "~bootstrap/scss/bootstrap";
@import 'var';
@import 'mixin';
@import 'auth';
@import 'forms';
@import 'button';
@import 'sidebar';
@import 'datepicker';
@import "dashboard";
@import "common";
@import "table";
@import "fonts";
@import "userfeedback";

 body {
    font-family: 'Barlow', sans-serif !important;
 }
