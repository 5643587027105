@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?1b2zwr');
    src:  url('../fonts/icomoon.eot?1b2zwr#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?1b2zwr') format('truetype'),
      url('../fonts/icomoon.woff?1b2zwr') format('woff'),
      url('../fonts/icomoon.svg?1b2zwr#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-perfume-management:before {
    content: "\e908";
    color: #6f6f6f;
  }
  .icon-report-icon:before {
    content: "\e900";
    color: #6f6f6f;
  }
  .icon-sign-out-icon:before {
    content: "\e901";
    color: #6f6f6f;
  }
  .icon-change-password-icon:before {
    content: "\e902";
    color: #6f6f6f;
  }
  .icon-invite-member-icon:before {
    content: "\e903";
    color: #6f6f6f;
  }
  .icon-user-feedback-icon:before {
    content: "\e904";
    color: #6f6f6f;
  }
  .icon-store-management-icon:before {
    content: "\e905";
    color: #8c8c8c;
  }
  .icon-user-profile-icon:before {
    content: "\e906";
    color: #6f6f6f;
  }
  .icon-dashboard-icon:before {
    content: "\e907";
    color: #6f6f6f;
  }