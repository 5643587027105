

.user-feedback-card {
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 20px 40px 10px rgba(224, 224, 224, 0.2);
    background-color: #fff;
    margin-bottom: 10px;
    .user-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user-object {
            display: flex;
            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
            .user-info {
                margin-left: 15px;
                .user-name {
                    font-size: 13px;
                    margin-bottom: 3px;
                }
                .user-date {
                    font-size: 12px;
                    color: #666666;
                    margin-bottom: 0;
                }
            }
        }
        .user-action-show {
            display: flex;
            align-items: center;
        }
    }
    .user-feedback-info {
        .user-feedback {
            font-size: 13px;
            color: #333;
            margin: 10px 0 10px 0;
        }
        .user-link {
            font-size: 14px;
            color: #333;
            margin-bottom: 0;
            font-weight: 400;
            // a {
            //     text-decoration: underline;
            // }
        }
    }
}
.user-feedback-sorting {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    box-shadow: 0 20px 40px 10px rgb(224 224 224 / 20%);
    background-color: #fff;
    display: flex;
    justify-content: center;
}
.user-short-detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 20px 40px 10px rgb(224 224 224 / 20%);
    background-color: #fff;

    .name {
        text-transform: capitalize;
    }
    .short-detail-wrap {
        .short-heading {
            font-size: 12px;
            color: #666;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 4px;
        }
        .short-des {
            font-size: 15px;
            color: #333;
            margin-bottom: 0;
        }
    }
}
.users-tab {
    .nav {
        width: 100%;
        border-radius: 10px;
        height: 50px;
        box-shadow: 0 20px 40px 10px rgb(224 224 224 / 20%);
        background-color: #fff;
        margin-bottom: 10px;
        .nav-item {
            width: 25%;
            display: flex;
            justify-content: center;
            &.dashboard-nav-item {
                width: 16.6% !important;
                .nav-link {
                    white-space: nowrap;
                }
            }
            .nav-link {
                border-radius: 0;
                text-align: center;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 600;
                color: #666666;
                &.active {
                    color: #202020;
                    background-color: transparent;
                    border-bottom: 1px solid #202020;
                }
            }
        }
    }
}
.dashboard-chart-bg {
    background-color: #fff;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
}
